import { AppDispatch, AppGetState } from '@/redux/store';
import { AugmentedSearchQuery, AugmentedSearchQueryOptions, SearchQuery } from '@/types/search/searchQuery';
import { fetchPromotedSearchResults } from '@/redux/modules/search/search.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from './browser';
import { getDeployment } from './config';
import { getIsBot } from './analytics';
import { getUserExcludedFacets } from './search/exclusions/searchExclusions.selectors';
import { SearchResultPayload } from '@/redux/modules/search/search.types';
import { TypedActionWithPayload } from '@/types/redux';

export const CLEAR_PROMOTED_SEARCH_RESULTS = 'CLEAR_PROMOTED_SEARCH_RESULTS';
export const LOAD_PROMOTED_SEARCH_RESULTS_FAIL = 'LOAD_PROMOTED_SEARCH_RESULTS_FAIL';
export const LOAD_PROMOTED_SEARCH_RESULTS_REQUEST = 'LOAD_PROMOTED_SEARCH_RESULTS_REQUEST';
export const LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS = 'LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS';
export type LoadPromotedSearchResultsSuccessAction = TypedActionWithPayload<
    typeof LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS,
    SearchResultPayload
>;

// reducer
export type SearchPromotedSlice = {
    all: number[];
    breadcrumbs: { id: string; name: string; url: string }[];
    itemIds: number[];
};

export const defaultSearchPromotedSlice: SearchPromotedSlice = {
    all: [],
    breadcrumbs: [],
    itemIds: [],
};

export default function reducer(state = defaultSearchPromotedSlice, action: any = {}): SearchPromotedSlice {
    switch (action.type) {
        case CLEAR_PROMOTED_SEARCH_RESULTS:
        case LOAD_PROMOTED_SEARCH_RESULTS_FAIL:
            return defaultSearchPromotedSlice;
        case LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS:
            const itemIDs = (action.payload.itemIDs || []) as number[];
            const breadcrumbs = action.payload.breadcrumbs || [];

            return {
                ...state,
                all: [...new Set([...state.all, ...itemIDs])], // prevent duplicates
                breadcrumbs,
                itemIds: itemIDs,
            };
        case LOAD_PROMOTED_SEARCH_RESULTS_REQUEST:
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const clearPromotedSearchResults = () => ({
    payload: { itemIDs: [] },
    type: CLEAR_PROMOTED_SEARCH_RESULTS,
});

type SearchPromotedPayload = {
    ids?: number[];
    pageName: string;
    searchQuery: SearchQuery;
};

export const searchPromoted =
    ({ ids, pageName, searchQuery }: SearchPromotedPayload) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);
            const ipAddress = getClientIpAddress(state);
            const isBot = getIsBot(state);

            if (pageName === 'catalog' || pageName === 'price-guide' || pageName === 'sold-search') {
                return dispatch(clearPromotedSearchResults());
            }
            dispatch({
                payload: searchQuery,
                type: LOAD_PROMOTED_SEARCH_RESULTS_REQUEST,
            });

            // Annotate facets which have user-defined search exclusions
            const searchExclusions = getUserExcludedFacets(state);
            const augmentedOptions: AugmentedSearchQueryOptions = Object.keys(searchExclusions).reduce(
                (options, facetWithExclusions) => ({
                    ...options,
                    [facetWithExclusions]: [
                        {
                            exclude: searchExclusions[facetWithExclusions] || [],
                            include: (searchQuery.options && searchQuery.options[facetWithExclusions]) || [],
                        },
                    ],
                }),
                {
                    ...searchQuery.options,
                    auctionHouse: [{ exclude: [], include: [] }],
                }
            );

            const augmentedSearchQuery: AugmentedSearchQuery = {
                ...(isBot ? { isBot } : {}),
                ...searchQuery,
                options: augmentedOptions,
                seoSearch: pageName === 'seo-search',
            };

            const results = await fetchPromotedSearchResults({
                authToken,
                deployment,
                ids,
                ipAddress,
                searchQuery: augmentedSearchQuery,
            });
            if (results.error) {
                dispatch({
                    error: true,
                    meta: { augmentedSearchQuery },
                    payload: results.payload,
                    type: LOAD_PROMOTED_SEARCH_RESULTS_FAIL,
                });
                return dispatch(clearPromotedSearchResults());
            } else {
                return dispatch({
                    meta: { actionTime: Date.now() },
                    payload: results.payload,
                    type: LOAD_PROMOTED_SEARCH_RESULTS_SUCCESS,
                });
            }
        } catch (error) {
            dispatch({
                error: true,
                meta: { searchQuery },
                payload: error,
                type: LOAD_PROMOTED_SEARCH_RESULTS_FAIL,
            });
            return dispatch(clearPromotedSearchResults());
        }
    };
